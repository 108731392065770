<template>
  <div class="litigationDetails">
    <van-nav-bar
      title="诉讼详情"
      left-arrow
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      :fixed="true"
      :border="false"
      :placeholder="true"
      v-show="is_navbar === 'true'"
    />
    <div class="litigationDetailsBody">
      <div class="litigationDetailsTitle">
        <div class="litigationDetailsTitleText">诉讼中…</div>
        <div class="litigationDetailsTitleContent">
          <div class="left">诉讼案件号：</div>
          <div class="right">{{ userInformation.lawsuit_no }}</div>
        </div>
        <div class="litigationDetailsTitleContent">
          <div class="left">索赔金额：</div>
          <div class="right">{{ userInformation.lawsuit_amount }}元</div>
        </div>
      </div>
      <div class="litigationDetailsBodyCenter">
        <div class="title">账号找回卖家信息</div>
        <div class="filedItem">
          <div class="filedItemLeft">找回人姓名</div>
          <div class="filedItemRight">{{ userInformation.real_name }}</div>
        </div>
        <div class="filedItem">
          <div class="filedItemLeft">手机号</div>
          <div class="filedItemRight">{{ userInformation.mobile }}</div>
        </div>
        <div class="filedItem">
          <div class="filedItemLeft">身份证号</div>
          <div class="filedItemRight">
            {{ userInformation.identity_card_number }}
          </div>
        </div>
        <div class="filedItem">
          <div class="filedItemLeft">线下地址</div>
          <div class="filedItemRight">
            {{ userInformation.address }}
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="litigationDetailsBodyCenter">
        <div class="title">游戏信息</div>
        <div class="filedItem">
          <div class="filedItemLeft">游戏账号</div>
          <div class="filedItemRight">{{ userInformation.account }}</div>
        </div>
        <div class="filedItem">
          <div class="filedItemLeft">游戏名称</div>
          <div class="filedItemRight">{{ userInformation.game_name }}</div>
        </div>
        <div class="filedItem">
          <div class="filedItemLeft">订单金额</div>
          <div class="filedItemRight">{{ userInformation.order_amount }}元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaterialDetail } from "@/api/security";
export default {
  name: "LitigationDetails",
  data() {
    return {
      userInformation: {},
      is_navbar: "true",
    };
  },
  mounted() {
    let config = {
      id: this.$route.query.id,
    };
    this.requestGetMaterialDetail(config);
    if (this.$route.query.is_navbar) {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async requestGetMaterialDetail(config) {
      this.loading = true;
      try {
        const result = await getMaterialDetail(config);
        if (result.code === 20000 || result.status_code === 1000) {
          this.userInformation = result.data;
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.litigationDetails {
  .litigationDetailsBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    .litigationDetailsTitle {
      width: 375px;
      height: 104px;
      background: #f3fbff;
      padding: 16px 18px 16px 20px;
      box-sizing: border-box;
      .litigationDetailsTitleText {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 22px;
      }
      .litigationDetailsTitleContent {
        margin-top: 8px;
        display: flex;
        .left {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #888888;
          line-height: 17px;
        }
        .right {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 17px;
        }
      }
    }
    .litigationDetailsBodyCenter {
      width: 335px;
      .title {
        width: 100%;
        height: 46px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        line-height: 46px;
        border-bottom: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .filedItem {
        width: 100%;
        height: 45px;
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .filedItemLeft {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
          line-height: 20px;
          text-align: center;
        }
        .filedItemRight {
          min-height: 20px;
          width: 197px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          text-align: end;
        }
      }
    }
    .line {
      width: 375px;
      height: 12px;
      background: #f2f2f2;
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: black;
}
</style>
